
  import Bot from 'models/bot'
  import ContextParameter from 'models/context_parameter'
  import ContextInterface from 'models/context_interface'

  import ContextInterfaceEditor from './context_interface_editor.vue'
  import ContextParameterCreator from './context_parameter_creator.vue'
  import ContextParameterEditor from './context_parameter_editor.vue'
  import LanguageSelector from 'components/botbuilder/language_selector.vue'
  # import Contextbadge from './contextbadge'
  import { BackLink, Button, DocumentationLink, H1, PageHeader } from 'components/generic'
  import { PencilIcon } from '@heroicons/vue/outline'

  export default
    props:
      bot: Bot
      parameterKey:
        type: String
        default: null

    data: ->
      contextInterface: null
      contextParameter: new ContextParameter()
      pristine: false
      editing: false
      debounceTimeout: null
      PencilIcon: PencilIcon

    computed:
      key: ->
        @parameterKey || @$route.params.parameterKey
      pageTitle: ->
        if @contextParameter.pristine then 'New context parameter' else @contextParameter.label

    watch:
      key: -> @setParameters()
      '$root.contextParameters': -> @setParameters()

    created: ->
      @setParameters()

    methods:
      setParameters: ->
        if !@key?
          @pristine = true
          return
        @pristine = false
        @contextParameter = @$root.contextParameters.find (parameter) => parameter.key == @key
        if @$route.params.parameterKey?
          window.breadcrumbs.append(text: @contextParameter.label)
        @contextInterface = await @bot.config.contextInterface(@contextParameter).load()
      updateContextParameter: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout((=> @contextParameter.save()), 1000)

    components:
      BackLink: BackLink
      Button: Button
      ContextInterfaceEditor: ContextInterfaceEditor
      ContextParameterCreator: ContextParameterCreator
      ContextParameterEditor: ContextParameterEditor
      DocumentationLink: DocumentationLink
      H1: H1
      LanguageSelector: LanguageSelector
      PageHeader: PageHeader
