
  import ContextParameter from 'models/context_parameter'

  import ContextParameterEditor from './context_parameter_editor.vue'
  import { Panel } from 'components/generic'

  export default
    props:
      contextParameter: ContextParameter

    data: ->
      copy: @contextParameter.clone()

    methods:
      cancel: ->
        @$router.push(name: 'contextParameterList')
      save: ->
        @contextParameter.update(@copy.export).create()
          .then =>
            @$root.contextParameters.push(@contextParameter)
            @$router.push(name: 'contextParameter', params: {parameterKey: @contextParameter.key})

    components:
      ContextParameterEditor: ContextParameterEditor
      Panel: Panel
