
  import BotConfig from 'models/bot_config.coffee'
  import { Button, CategoryTableRow, H1, Input, Table, TableCell, TableHeadCell, TableHeadRow, TableRow, TableWrapper } from 'components/generic'
  import { LightningBoltIcon, SearchIcon } from '@heroicons/vue/solid'

  export default
    props:
      config: BotConfig

    data: ->
      query: ''
      rowRefs: {}

    computed:
      filteredContextParameters: ->
        return @$root.contextParameters if @query.length == 0
        q = @query.toLowerCase()
        @$root.contextParameters.filter (cp) -> cp.label.toLowerCase().startsWith(q)
      parametersByCategory: ->
        categories = @filteredContextParameters
          .map (cp) -> cp.computedCategory.decapitalize()
          .unique()
          .sort()
        ['custom', 'user', 'legal', 'setting'].forEach (category) ->
          index = categories.indexOf(category)
          return if index == -1
          categories.splice(index, 1)
          categories.push(category)
        Object.fromEntries(categories.map (category) =>
          [
            category,
            @filteredContextParameters
              .filter (cp) -> cp.computedCategory.decapitalize() == category
              .sort()
          ]
        )

    created: ->
      @$root.appData.contextParameterList ||= {}
      @$root.appData.contextParameterList.categoriesOpen ||= {}

    beforeUpdate: ->
      @rowRefs = {}

    methods:
      setRowRef: (category, el) ->
        return unless el?
        @rowRefs[category] ||= []
        @rowRefs[category].push(el)
      valueProviderInitiative: (contextParameter) ->
        @config.contextInterface(contextParameter).valueProvider == 'initiative'
      defaultValue: (contextParameter) ->
        @config.contextInterface(contextParameter).defaultValue

    components:
      Button: Button
      CategoryTableRow: CategoryTableRow
      H1: H1
      Input: Input
      LightningBoltIcon: LightningBoltIcon
      SearchIcon: SearchIcon
      Table: Table
      TableCell: TableCell
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableRow: TableRow
      TableWrapper: TableWrapper
