
  import ContextParameter from 'models/context_parameter.coffee'
  import ContextInterface from 'models/context_interface.coffee'
  import Validation from 'models/validation.coffee'
  import Verbalization from 'models/verbalization.coffee'

  import ContextInitiativeAdvancedEditor from './context_initiative_advanced_editor.vue'
  import DataTypeDependentInput from '../inputs/data_type_dependent_input.vue'
  import ExpectedValuesEditor from './expected_values_editor.vue'
  import OperationalHooksEditor from './operational_hooks_editor.vue'
  import UtteranceCollectionEditor from 'components/botbuilder/utterances/utterance_collection_editor.vue'
  import ValidationsEditor from './validations_editor.vue'
  import VerbalizationPanel from './verbalization_panel.vue'
  import VerbalizationEditor from './verbalization_editor.vue'
  import { DocumentationLink, H3, H4, Label, RadioPanels, Switch, Tabs } from 'components/generic'
  import { PencilIcon } from '@heroicons/vue/outline'

  export default
    props:
      contextInterface: ContextInterface
      contextParameter: ContextParameter

    emits: ['edit']

    data: ->
      debounceTimeout: null
      cpDebounceTimeout: null
      currentTab: 'dialog'
      tabs: [
        {id: 'dialog', name: 'Dialog'}
        {id: 'input', name: 'User input'}
        {id: 'advanced', name: 'Advanced'}
      ]
      currentVerbalization: null
      ContextInterface: ContextInterface
      Verbalization: Verbalization
      validationsOn: 'off'
      expectedValuesOn: 'off'
      validationOptions: [
        {key: 'off', label: 'Off', description: 'All values allowed.'}
        {key: 'on', label: 'On', description: 'Restrict the possible values by defining validation rules.'}
      ]
      expectedValuesOptions: [
        {key: 'off', label: 'Off', description: 'Work with free input values in the bot editor.'}
        {key: 'on', label: 'On', description: 'Support bot editing by providing a list of values.'}
      ]

    computed:
      verbalizations: ->
        Object.keys(Verbalization.TYPES).map (type) => @contextInterface.initiative[type.decapitalize()]
      verbalizationsWithoutQuery: ->
        @verbalizations.filter (verbalization) -> verbalization.type != 'QueryVerbalization'
      fixedSlotDefinition: ->
        return if @contextInterface.initiative.slotDefinitions?
        {slotRole: @contextParameter.key, valueType: @contextParameter.valueType}

    watch:
      'contextInterface.valueProvider': ->
        if @contextInterface.valueProvider == 'defaultValue'
          @currentVerbalization = null
          @$nextTick =>
            @$refs.defaultValueInput.focus()
        else
          @contextInterface.initiative.queryVerbalization.isActive = true
          @edit(@contextInterface.initiative.queryVerbalization)
        @updateWithDebounce()

    created: ->
      @setOnOffValues()

    methods:
      setOnOffValues: ->
        @validationsOn = if @contextParameter.validationRules.length > 0 then 'on' else 'off'
        @expectedValuesOn = if @contextParameter.expectedValues.length > 0 then 'on' else 'off'
      updateValidations: ->
        if @validationsOn == 'on'
          @contextParameter.validationRules.push(new Validation())
        else
          @contextParameter.validationRules = []
        @updateContextParameter()
      updateExpectedValues: ->
        if @expectedValuesOn == 'on'
          @contextParameter.expectedValues.push('')
        else
          @contextParameter.expectedValues = []
        @updateContextParameter()
      updateOnOffValues: ->
        @setOnOffValues()
        @updateContextParameter()
      active: (verbalization) ->
        return false if !@currentVerbalization?
        @currentVerbalization == verbalization
      toggle: (verbalization) ->
        if @currentVerbalization == verbalization
          @currentVerbalization = null
        else
          @edit(verbalization)
      edit: (verbalization) ->
        # We need to do this trick in order to trigger immediate saving of message texts.
        # Otherwise the old verbalization's texts might show up in the new verbalization.
        @currentVerbalization = null
        @$nextTick =>
          @currentVerbalization = verbalization
      close: (verbalization) ->
        @currentVerbalization = null if @currentVerbalization == verbalization
      updateWithDebounce: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout(@update, 1000)
      update: ->
        @contextInterface.save()
      updateContextParameter: ->
        clearTimeout(@cpDebounceTimeout)
        @cpDebounceTimeout = setTimeout((=> @contextParameter.save()), 1000)
      activate: (verbalizationTypes) ->
        verbalizationTypes.forEach (type) =>
          @contextInterface.initiative[type.decapitalize()].isActive = true
        @contextInterface.valueProvider = 'initiative'
        @update()
      deactivate: (verbalizationTypes) ->
        verbalizationTypes.forEach (type) =>
          @contextInterface.initiative[type.decapitalize()].isActive = false
        if !(@contextInterface.isUserParameter || @contextInterface.initiative.queryVerbalization.isActive)
          @contextInterface.valueProvider = 'defaultValue'
        @update()

    components:
      ContextInitiativeAdvancedEditor: ContextInitiativeAdvancedEditor
      DataTypeDependentInput: DataTypeDependentInput
      DocumentationLink: DocumentationLink
      ExpectedValuesEditor: ExpectedValuesEditor
      H3: H3
      H4: H4
      Label: Label
      OperationalHooksEditor: OperationalHooksEditor
      PencilIcon: PencilIcon
      RadioPanels: RadioPanels
      Switch: Switch
      Tabs: Tabs
      UtteranceCollectionEditor: UtteranceCollectionEditor
      ValidationsEditor: ValidationsEditor
      VerbalizationEditor: VerbalizationEditor
      VerbalizationPanel: VerbalizationPanel
